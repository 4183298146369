import React from 'react';
import Shell from 'components/Shell/Shell';
import { Contentful, getMasterControl } from '@viome/vnxt-nucleus';
import { contentfulOptions as options } from 'utils/constants';
import Schema from '../utils/schema/Home';
import SectionModules from '../containers/Sections/SectionModules';
import ErrorPage from './404';

const Page = (props) => {
    const { preview, masterControl, page } = props,
        sections = page?.content?.sections.items || [];

    if (page.error) {
        return <ErrorPage />;
    }

    return (
        <Shell
            masterControl={masterControl}
            page={page}
            preview={preview}
            className="viome3"
        >
            <SectionModules modules={sections} />
            <Schema />
        </Shell>
    );
};

export default Page;

export async function getStaticProps({ preview = false }) {
    const pageData = Promise.all([
            getMasterControl.get(options),
            Contentful(
                'page',
                {
                    include: 10,
                    'fields.slug[in]': `vls-home`,
                },
                false,
                options,
            ),
        ]),
        props = await pageData.then(async ([masterControl, page]) => ({
            preview,
            masterControl,
            page: page ?? null,
        }));

    return { props, revalidate: 60 };
}
